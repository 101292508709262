<template>
  <div id="app">
    <template v-if="success == 1">
      <view style="width: 100%; height: 100%; position: fixed; top: 0; left: 0; background-color: white; z-index: 300;"></view>
      <view style="width: 100%; height: 100%; position: fixed; top: 0; left: 0; z-index: 301; display: flex; justify-content: center; align-items: center;">
        <div style="width: 80%; background-color: white; padding: 5%; margin: 5%; border-radius: 12px; position: relative;">
          <img src="/success.png" style="width: 100px; height: 100px; display: block; margin: 0 auto;">
          <div style="font-size: 20px; color: #6b7280; margin-top: 8px; font-weight: bold;">Very Lucky</div>
          <div style="font-size: 15px; color: #6b7280; margin-top: 8px;">Authentication Successful</div>
          <div style="font-size: 15px; color: #6b7280; margin-top: 8px;">Please continue shopping on the machine</div>
          <img v-if="imgUrl" :src="imgUrl" style="background-color:red; width: 60px; height: 80px; border-radius: 5px; position: absolute; top: 15px; right: 15px;" />
        </div>
      </view>
    </template>
    <template v-else-if="success == 0">
      <view style="width: 100%; height: 100%; position: fixed; top: 0; left: 0; background-color: white; z-index: 300;"></view>
      <view style="width: 100%; height: 100%; position: fixed; top: 0; left: 0; z-index: 301; display: flex; justify-content: center; align-items: center;">
        <div style="width: 80%; background-color: white; padding: 5%; margin: 5%; border-radius: 12px; position: relative;">
          <img src="/error.png" style="width: 100px; height: 100px; display: block; margin: 0 auto;">
          <div style="font-size: 20px; color: #6b7280; margin-top: 8px; font-weight: bold;">I'm sorry</div>
          <div style="font-size: 15px; color: #6b7280; margin-top: 8px;">Authentication Failure</div>
          <div style="font-size: 15px; color: #6b7280; margin-top: 8px;">Tips：{{tips}}</div>
        </div>
      </view>
    </template>
    <template v-else>
      <div style="display: flex; padding: 10px 5%; background-color: #FFFFFF;">
        <img src="@/assets/logo.png" style="display: block; height: 46px; width: 100px; padding: 0 5% 0 0;"/>
        <div style="height:50px; line-height: 25px; font-size: 18px; color: #000; text-align: left; border-left: 1px solid #dcdfe6; padding-left: 5%; font-weight: 500;">
          Reyeah Document Verification Service
        </div>
      </div>
      <!-- <div style="display: flex; padding: 10px 20px; padding: 20px;">
        <div style="width: 100%; display: flow-root; position: relative; margin: 10px 0; background-color: #fef8ea; color: #f7ba2a; padding: 12px 16px; border-radius: 4px; font-size: 14px; line-height: 18px; text-align: left;">
          <div style="display: table-cell; padding: 0 8px; line-height: 1.5; font-size: 14px;">
            Mobile device cameras are recommended when trying to demo DVS with document types that use PDF417 barcodes (e.g. US Drivers Licenses, US Identification Cards, etc. ), if you are using a laptop or desktop with a lower quality web camera we recommend using a document type that uses an MRZ (e.g. US Passports, US Green Cards, US Passport Cards, etc. ) for best results.
          </div>
          <div style="position: absolute; top: 10px; right: 20px; font-size: 12px; color: #c0c4cc;">x</div>
        </div>
      </div> -->
      <div id="videoCapturingEl" style="margin: 0 5%; padding-top: 5%;"></div>
    </template>
  </div>
</template>

<script>
import IDVC from "@idscan/idvc2";
// import Vconsole from "vconsole";
export default {
  name: "App",
  data() {
    return {
      success: 2,
      tips: 'Please continue shopping on the machine',
      imgUrl: '',
      machineNo: '868821048968465',
      age: 18,
    }
  },
  mounted: function () {
    let _self = this;
    // 初始化控制台
    // new Vconsole();
    let idvc = new IDVC({
      // 页面上 html 标记的ID
      el: "videoCapturingEl",
      // Web 库的许可证密钥
      // localhost
      // licenseKey: "eyJwZGY0MTdrZXkiOiJFZEF6UUVvcVhWUGNJQUdKM1lkOEQxQWhxbzBqZ3liTnVHU0FmZU1wV2luVTJCQUdvaeyJwZGY0MTdrZXkiOiJFZEF6UUVvcVhWUGNJQUdKM1lkOEQxQWhxbzBqZ3liTnVHU0FmZU1wV2luVTJCQUdvanFWUktEbEg0U2pNZDJkbDd6NXJvbW94bk5sY2lKcUkyVkw5cGJEM2RHOUtML0cxOGlzQS9ENmNlNmdBeFdmVmRUT1daaUNWMnhybjhHMERudmxVK1dNOUhEQ3ZrSW5BN2ZtRVlCcGo4SS9tWXFlU0cwOVZ2ZWorY2s9IiwiaW1hZ2VQcm9jZXNzaW5nS2V5IjoiSDRRTmxmNW53ZEJNQnFDQTlHSWxQVGZqYWNCaTU2NVpWTE9DTWlpT2FTcE4xYUFYVk9wWmxZNWdCa1EvTEJiR01CYXNHaDREenhkUTh4NXNWejRyTkJBUVlIbWZ3a09taGlGeW5zMDB1bHZ4Y0tJK1FINmd2QldMTGJsbDA2RDZzWFVWUVNCRFBBdXkyWlFJaTVvUTdzeUdoS2NqdnlLY3RCQXorTGU4OUVBPSIsInRyYWNrU3RyaW5nUGFyc2VyS2V5IjoiSmpSeGMrbGpud2ZJMzRTOEl4NEJxRjFGbGU2YmI2bzh0QVR0WW9ucDNvdkJzSHVLNnkxY09MTWROZEE3Zmh1c3hodVR6aTNvNHprM2pmOWwzTXpHUWxraEY4c0x4OEtHNjRTSHUrcUl5NmZ3cy8vVFFwK2hqUUhHMjFQUFEvYXZvS0tTOEdrT0s5d1UrdHA5a2RZYVpuWk53UVArYUlDQS85bUQ3bEpGMkMwPSIsImNvbW1vbkxpY2Vuc2VLZXkiOiJuVkE2QTVWQVlLQ21xb0FYY0dxajZjWEN6cmhjT09haU05RmpESXNKemlkS2c4NEl5ejJVd0Zldmd4d2JXeW1QWGllRjZKV3hhaEk3YkdYRU94M1lwc3E5cTFtWklDRFhxSlgvM2NPZzV0anRNNmxrd0ZWR0RvQlBuVWZTTlF6ZWVlc1cwVmNZczFEZ1dLQXhhTDgreVk5c2ZRcDJWTmE4Z09pM0t2Q0lkWkk9In0=",
      // 10.0.0.160
      // licenseKey: "eyJwZGY0MTdrZXkiOiJvSGtUbUlaTVhjTVY0cHBpWTRWWkJFeHJoSTlZQ1FHby9aMVRSa1M3dlpoaU1yTHNYQ2Z6UUxJN1YrcEwvWXVDZW1PWmFRckRGZGg0TlM0ZWtwWjJIbjcrSUdXekdQTEltWEk1UUVPUThWV2Q2WXRPMjIvRFA2cWNwVmlQMkdhbEpNMEdSczVXai9YYXV2c2k3WGVqMEpyNURvUm1oL2h6dVJ1L0N0VHdxQU09IiwiaW1hZ2VQcm9jZXNzaW5nS2V5Ijoib0FndDQ5ZHJ4UEo0RitFdzF4QUtseGNibEwyd1R0NzY4enZsUlpoUkFVaktYeldtMXYrdGovT3RGUlVNTVc4Q293QjFkR09hWFBWWG11RUxUL2lGQWxBY2RpRnpjajZaR1JuWUtzNG1vbHNjUzJLRjNvYmZxSmNlTHVxcElScS8vVzZmaDdmc1NVVjl5VDI1QzBZZ01lYngrSjJxRDh6S2tUUUU0MWhyUVBnPSIsInRyYWNrU3RyaW5nUGFyc2VyS2V5IjoiWVAzaW5uM1NnZ0RGU2xzQ1NhWm5ya2VVNWlhRk85dXV4RTBRU1Rhc0liMnNwaUNKKzFyaTN1Y2hnL1NHMDNqanMrTEFCOHBHaGphMitYVnBsdjFWajNVZ1FQRWVNK0tUNlUzd2x6TkNuQnBZeEVFOEdqdEdwQVk0SUZVY3JtQmp4blZQSFpqYTFEQTZNdEFrUElvYVBIVkM4Zm9vejRZUE9iQlE1YlBDaGhRPSIsImNvbW1vbkxpY2Vuc2VLZXkiOiJLMDNxMVBWd3RvS1M1K01jZE1jNkpvWjl4bGRMR3ZaZU5DZXZ4WUwySEdvTFc4clMxOUp6ZkdKTVEwSTc2c3VlYTZISnZQQkJQaEQvb0xraWdOQ3dDaExGeVlYRnI1RjN0MWlhYW9NMXlvUkYzQ0lNd3hYNU1wbFQ2WHBmUUY0eGFKTUtsQ3orTGZlOEZUaVZ2NisybWNmK0R4UWZNUXVKcG93bUhwQWFIdEE9In0=",
      // (*.reyeah.com)
      licenseKey: "eyJwZGY0MTdrZXkiOiJsZWxHMEFmbk53c2I1Uk9CVmZqT3lrNVRGdmE4dHZJQ3VmbExoMlFkUnphSzBrVGhyOFYzZC9ZckI3cXBzMnluYjRmNm5NQmVHOGlNaWs3LzE5SVVrMm9ONGlEWXRXMUxKREtYdVRwcUx4ZXNOcHpCTUpaTDU2MXV0aUlMR1Q3eHVveHJZTGV4OW5MdkZHc2JkUGhCeFM3VHZ5MStuZ08yNUsyVGZGUEE2VmM9IiwiaW1hZ2VQcm9jZXNzaW5nS2V5IjoiQjdLS0IzK2ZVTlhIVlhhaWJpeXRLUG5CK1JTUWdRRzBSTWRhS0lhdmtHc2RGR0hyRjBtNlRNSko1VHN0NXVzVElvbDdDSkNLRjlEbzlNRU5ZN01jUjFrbERXY3pKa0lyWnpXb1FBR2pCb0lHY1ltanhLbDB6U1kxb1cwamlRRmpSQlZFWEloSTRtZWVldldqNGNkaE1ZeCt1d282NzYzK2RCNklYMzFYT2N3PSIsInRyYWNrU3RyaW5nUGFyc2VyS2V5IjoicXlKUDdpdlNjcXQxT1dFSmRlUzMwb3MyT1lHS1VwVGF3SjZjY2JWQk9oT0h5UGFjZE90T0l1NFdlM3g3Nkd5Tk0wQ3ZEUU9wMG5PWVhjQnN6dGNTRWxJbE1sSFJsd3o2b3lWRjVORTNpQXoycllVdkhQVFRQenEzYzEyQk54bm1xd0cwVk5wZ2ZzY1A4cGlGYWpCNnhlMEd2d29ZQWtDT0hNajkxOUNoWWlBPSIsImNvbW1vbkxpY2Vuc2VLZXkiOiJEZzBadERCemdMaFU0U056QTBSQUJDUU5FLzIzWXl1cGE3OVJZZVE3VGxlL3pIUm5kOUpIZlVmajJTSG9MRk9ERG85eklkSzkrNWc4a1dYOThIV1J2N2prVUc3Wkg1eWdtb3lMcVAydWEzODNWeE1jZHhtS0ZiWlZPRnRnY21OM0R2VFBlUDFjblNCVUZ1UEswZGhvSUtzZjdrbFpqRlBXT1pPazZkNklmRVE9In0=",
      useCDN: true,
      networkUrl: "networks",
      resizeUploadedImage: 1600,
      fixFrontOrientAfterUpload: true,
      autoContinue: true,
      realFaceMode: "auto",
      // 配置语言
      language: "en",
      // 打开/关闭按钮以显示指南或者使用协议
      isShowGuidelinesButton: true,
      isShowVersion: false,
      isShowDocumentTypeSelect: false,
      documentTypes: [
        {
          type: "ID",
          steps: [
            {
              type: "front",
              name: "Document Front",
              mode: { uploader: true, video: true },
            },
            {
              type: "pdf",
              name: "Document PDF417 Barcode",
              mode: { uploader: true, video: true },
            },
            {
              type: "face",
              name: "Face",
              mode: { uploader: true, video: true },
            },
          ],
        }
        // ,
        // {
        //   type: "Passport",
        //   steps: [
        //     {
        //       type: "mrz",
        //       name: "Passport Front",
        //       mode: { uploader: true, video: true },
        //     },
        //     {
        //       type: "face",
        //       name: "Face",
        //       mode: { uploader: true, video: true },
        //     },
        //   ],
        // }
      ],
      // 内容改变触发
      onChange(data) {
        console.log("on change", data);
      },
      onCameraError(data) {
        // 摄像头打开失败
        this.success = 0;
        this.tips = data;
      },
      // 全部重新认证
      onReset(data) {
        console.log("on reset", data);
      },
      // 重新人脸
      onRetakeHook(data) {
        console.log("retake hook", data);
      },
      // 查看规格按钮
      clickGuidlines() {
        console.log("click Guidelines");
      },
      // 提交认证
      submit(data) {
        idvc.showSpinner(true);
        let frontStep, pdfStep, faceStep, mrzStep;
        let frontImage, backImage, faceImage;
        let trackString;
        let captureMethod;
        let verifyFace = true;
        switch (data.documentType) {
          // Drivers License and Identification Card
          case 1:
            frontStep = data.steps.find((item) => item.type === "front");
            pdfStep = data.steps.find((item) => item.type === "pdf");
            faceStep = data.steps.find((item) => item.type === "face");
            frontImage = frontStep.img.split(/:image\/(jpeg|png);base64,/)[2];
            backImage = pdfStep.img.split(/:image\/(jpeg|png);base64,/)[2];
            faceImage = faceStep.img.split(/:image\/(jpeg|png);base64,/)[2];
            trackString = pdfStep && pdfStep.trackString ? pdfStep.trackString : "";
            captureMethod =
              JSON.stringify(+frontStep.isAuto) +
              JSON.stringify(+pdfStep.isAuto) +
              JSON.stringify(+faceStep.isAuto);
            break;
          // US and International Passports
          case 2:
            mrzStep = data.steps.find((item) => item.type === "mrz");
            faceStep = data.steps.find((item) => item.type === "face");
            frontImage = mrzStep.img.split(/:image\/(jpeg|png);base64,/)[2];
            faceImage = faceStep.img.split(/:image\/(jpeg|png);base64,/)[2];
            trackString = mrzStep && mrzStep.mrzText ? mrzStep.mrzText : "";
            captureMethod =
              JSON.stringify(+mrzStep.isAuto) +
              JSON.stringify(+faceStep.isAuto);
            break;
          // US Passport Cards
          case 3:
            break;
          // US Green Cards
          case 6:
            break;
          // International IDs with 3 line MRZs
          case 7:
            frontStep = data.steps.find((item) => item.type === "front");
            mrzStep = data.steps.find((item) => item.type === "mrz");
            faceStep = data.steps.find((item) => item.type === "face");
            frontImage = frontStep.img.split(/:image\/(jpeg|png);base64,/)[2];
            backImage = mrzStep.img.split(/:image\/(jpeg|png);base64,/)[2];
            faceImage = faceStep.img.split(/:image\/(jpeg|png);base64,/)[2];
            trackString = mrzStep && mrzStep.mrzText ? mrzStep.mrzText : "";
            captureMethod =
              JSON.stringify(+frontStep.isAuto) +
              JSON.stringify(+mrzStep.isAuto) +
              JSON.stringify(+faceStep.isAuto);
            break;
          case 8:
            // photoStep = data.steps.find((item) => item.type === "photo");
            // photoImage = photoStep.img.split(/:image\/(jpeg|png);base64,/)[2];
            // captureMethod = JSON.stringify(+photoStep.isAuto);
            // verifyFace = false;
            break;
          case 9:
            // barcodeStep = data.steps.find((item) => item.type === "barcode");
            // barcodeImage = barcodeStep.img.split(/:image\/(jpeg|png);base64,/)[2];
            // captureMethod = JSON.stringify(+barcodeStep.isAuto);
            // verifyFace = false;
            break;
          default:
        }
        let request = {
          frontImageBase64: frontImage,
          backOrSecondImageBase64: backImage,
          faceImageBase64: faceImage,
          documentType: data.documentType,
          trackString: trackString,
          ssn: null,
          overriddenSettings: null,
          userAgent: window.navigator.userAgent,
          captureMethod: captureMethod,
          verifyFace: verifyFace,
        };
        fetch("https://dvs2.idware.net/api/v3/Verify", {
          method: "POST",
          headers: {
            Authorization:"Bearer sk_5c53bcd9-79b1-480e-b03f-b95b7e83f43a",
            "Content-Type": "application/json;charset=utf-8",
          },
          body: JSON.stringify(request),
        }).then((response) => response.json()).then((data) => {
          idvc.showSpinner(false);
          // // 证件识别验证过程的结果处理
          let documentVerificationResult = data.documentVerificationResult?data.documentVerificationResult:'';
          // 证件真实可信度
          if(documentVerificationResult.totalConfidence < 70){
            _self.success = 0;
            _self.tips = 'The document is suspected to be fake. Please submit it again for verification';
            return false;
          }
          if(documentVerificationResult.statusString == 'ItsFake' && documentVerificationResult.status == 1){
            _self.success = 0;
            _self.tips = 'The document is fake';
            return false;
          }else if(documentVerificationResult.statusString == 'Expired' && documentVerificationResult.status == 2){
            _self.success = 0;
            _self.tips = 'The document has expired';
            return false;
          }else if(documentVerificationResult.statusString == 'LowConfidence' && documentVerificationResult.status == 3){
            _self.success = 0;
            _self.tips = 'The document has expired';
            return false;
          }
          // 证件的人脸与上传照片的比较结果
          let faceVerificationResult = data.faceVerificationResult?data.faceVerificationResult:'';
          if(faceVerificationResult.antiSpoofing >= 70){
            _self.success = 0;
            _self.tips = 'This face is not me.';
            return false;
          }
          if(faceVerificationResult.confidence < 70){
            _self.success = 0;
            _self.tips = 'Face match failed.';
            return false;
          }
          // 年龄检测
          let documents = data.document?data.document:'';
          let dob = documents.dob;
          if(!dob){
            _self.success = 0;
            _self.tips = 'Age identification failure.';
            return false;
          }
          let dobset = dob.split('-');
          let date = new Date();
          let year = date.getYear();
          console.log(dobset);
          if(parseInt(dobset[0]) < 1920){
            _self.success = 0;
            _self.tips = 'Age identification failure.';
            return false;
          }else if(parseInt(dobset[0]) > parseInt(year)){
            _self.success = 0;
            _self.tips = 'Age identification failure.';
            return false;
          }
          let localage = (year - parseInt(dobset[0]));
          console.log(localage);
          console.log(parseInt(_self.age));
          if(localage < parseInt(_self.age)){
            _self.success = 0;
            _self.tips = 'Currently, only users over the age of '+parseInt(_self.age)+' are supported.';
          }else{
            _self.success = 1;
            _self.tips = '';
          }
          // 请求参数
          let body = {
            message: _self.success,
            topic: _self.machineNo,
            type: 'AGE'
          };
          // 发送MQ
          fetch("https://api.control.reyeah.com/h5Interface/sendMqtt", {
            method: "POST",
            headers: {
              'Content-Type':'application/json',
              'appId': '123456789'
            },
            body: JSON.stringify(body),
          }).then((response) => response.json()).then((data) => {
            console.log(data);
          }).catch((err) => {
            console.log(err);
          });
        }).catch((err) => {
          idvc.showSpinner(false);
          // 认证失败
          _self.success = 0;
          _self.tips = err;
        });
      },
    });
  },
  methods: {
    handleSwitch(){
      this.success = 0;
    }
  }
};
</script>

<style>
@import "../node_modules/@idscan/idvc2/dist/css/idvc.css";
body{
    margin: 0;
    font-family: Segoe UI,Arial,Helvetica Neue,Helvetica,sans-serif;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  background-color: #e9ecf3;
}
#videoCapturingEl{
  text-align: center!important;
  padding-bottom: 10px!important;
}
</style>
